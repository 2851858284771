import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Contact Thai Village Cuisine" />
    <div className="contact-bg"></div>
    <section className="no-border">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <h2>Get in touch</h2>
            <p>
              Our resturant is located at
              <br />
              34571 Alvarado-Niles Rd. Union City, CA 94587
            </p>
            <h3>Hours</h3>
            <p><b>TUESDAY - SUNDAY</b><br />
            11:00 AM - 3:00 PM<br />
            5:00 PM - 9:00 PM<br />
            </p>
            <p><b>MONDAY CLOSED</b></p>

            <h3>Call us</h3>
            <p>Tel: 510.441.8424</p>

          </div>
          <div className="col-12 col-md-6">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3161.6703414177673!2d-122.02215668468774!3d37.58637967979424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fbfdf3cd87947%3A0xe176adfc7b90decc!2s34571%20Alvarado-Niles%20Rd%2C%20Union%20City%2C%20CA%2094587%2C%20USA!5e0!3m2!1sen!2sth!4v1631324658299!5m2!1sen!2sth" 
              frameborder="0"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0" />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default SecondPage
